import React from 'react'
import { graphql, navigate } from 'gatsby'
import styled from 'styled-components'
import { Container, Row, Col, Button } from 'reactstrap'
import Layout from '../components/layout'

import Seo from '../components/shared/Seo'
import Icons from '../components/shared/Icons'

import Spacer from '../components/spacerHelper'

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 2em;
`

const Feature = styled.li`
  margin-bottom: 0.5em;
  padding: 0;
`

class Pricing extends React.Component {
  componentDidMount() {
    window !== undefined &&
      window.addEventListener(
        'resize',
        Spacer(['.pricing-lead', '.pricing-spacer'])
      )
  }
  componentWillUnmount() {
    window !== undefined && window.removeEventListener('resize', Spacer)
  }
  render() {
    const {
      pricing: { text },
      pricing,
      pricing: { plans },
    } = this.props.data
    return (
      <Layout>
        <Seo {...this.props.data.seo} />
        <Container className="py-5">
          <Row>
            {plans.map((plan, i) => (
              <Col md="4" key={i}>
                <div
                  className=" p-5  "
                  style={{ background: 'rgba(253,215,201,0.4)' }}
                >
                  <div className="text-center">
                    <h3 className="text-gold  ">{plan.title} </h3>
                    <p className="pb-4 mb-0 pricing-lead">{plan.lead}</p>

                    <h1 className="display-3 mb-0">{plan.price}</h1>
                    <p className="pb-4">{plan.explanation}</p>
                  </div>
                  <div className="text-center">
                    <div className="pricing-spacer">
                      <List className=" ">
                        {plan.features.map((feature, i) => (
                          <Feature key={i}> {feature}</Feature>
                        ))}
                      </List>
                    </div>

                    <Button
                      onClick={e => {
                        e.preventDefault()
                        navigate('/contact', {
                          state: {
                            selected: `Request a Demo`,
                          },
                        })
                      }}
                      className="d-block mx-auto primary-btn-gradient py-2 px-5 mt-3"
                    >
                      {plan.buttonLabel}
                    </Button>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <h3 className="text-center text-primary py-3 mt-4">{text}</h3>
        </Container>
        <Icons />
      </Layout>
    )
  }
}

export default Pricing

export const query = graphql`
  {
    pricing: contentfulPricingPage {
      id
      text
      plans {
        id
        title
        lead
        price
        explanation: priceExplanation
        buttonLabel
        buttonLink
        features
      }
    }
    seo: contentfulPricingPage {
      title: pageTitle
      description
      keywords
    }
  }
`
